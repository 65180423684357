exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cabinet-js": () => import("./../../../src/pages/cabinet.js" /* webpackChunkName: "component---src-pages-cabinet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motions-js": () => import("./../../../src/pages/motions.js" /* webpackChunkName: "component---src-pages-motions-js" */),
  "component---src-pages-representatives-js": () => import("./../../../src/pages/representatives.js" /* webpackChunkName: "component---src-pages-representatives-js" */),
  "component---src-pages-senate-js": () => import("./../../../src/pages/senate.js" /* webpackChunkName: "component---src-pages-senate-js" */),
  "component---src-templates-motion-category-template-js": () => import("./../../../src/templates/motion-category-template.js" /* webpackChunkName: "component---src-templates-motion-category-template-js" */),
  "component---src-templates-motion-template-js": () => import("./../../../src/templates/motion-template.js" /* webpackChunkName: "component---src-templates-motion-template-js" */),
  "component---src-templates-party-template-js": () => import("./../../../src/templates/party-template.js" /* webpackChunkName: "component---src-templates-party-template-js" */),
  "component---src-templates-people-template-js": () => import("./../../../src/templates/people-template.js" /* webpackChunkName: "component---src-templates-people-template-js" */),
  "component---src-templates-votelog-list-template-js": () => import("./../../../src/templates/votelog-list-template.js" /* webpackChunkName: "component---src-templates-votelog-list-template-js" */),
  "component---src-templates-votelog-template-js": () => import("./../../../src/templates/votelog-template.js" /* webpackChunkName: "component---src-templates-votelog-template-js" */)
}

